.desktop-fairmile {
  font-family: "Slate Pro", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #003087;
}
.drawer-companyName {
  font-family: "Slate Pro", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
}
.drawer-policyNum {
  font-family: "Slate Pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
}

.drawer-option {
  font-family: "Slate Pro", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
}
.selected-drawer-option {
  color: #003087;
}
.sidebar-content {
  position: relative;
  left: 0;
  margin-top: 85px;
  width: 300px;
  height: 100%;
  --background: white;
  border-right: 1px solid #e7e7e7;
}
#main-menu-content {
  margin-top: 84px;
}

#main-menu-content-logged-in {
  margin-top: 84px;
  margin-left: 300px;
}
.link-selected .drawer-option,
.link-selected {
  color: #003087;
  font-weight: 600;
}
