ion-card.md ion-card-content.md {
  padding-right: 10px;
  padding-left: 10px;
}

ion-content {
  --background: none;
}

.truncate {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop-card {
  width: 454px;
  height: 404px;
}

body {
  font-family: SFProText, Roboto, sans-serif;
}

.h2 {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 1;
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

/* a subtle focus style for keyboard-input elements */
.text-input:focus {
  outline: 1px solid #aaa;
}

body:not(.keyboardUser) *:focus {
  outline: none;
}
