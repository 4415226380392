.selective-back {
  color: #003087;
  font-size: 10px;
}

.selective-back::part(native) {
  padding-left: 0px;
}

/* .selector-container {
  padding-left: 20px;
} */

/* .selector-container.ios {
  border-bottom: 1px solid grey;
} */

.common-menu {
  --padding-start: 0;
}
ion-select.vehicle-selector-select.insights-title.select-insights.desktop::part(icon) {
  position: absolute;
  right: 15px;
  color: #585858;
  opacity: 1;
}
ion-select.vehicle-selector-select.insights-title.select-insights.desktop.ios::part(icon) {
  right: 20px;
}

/* Set the placeholder color and opacity */
ion-select::part(text) {
  /* padding-top: 20px; */
  font-size: 18px;
}
