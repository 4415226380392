.custom-input {
  width: 100%;
  margin-inline-end: 0px;
  position: relative;
}

.custom-input .custom-input-label.ios {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #585858;
  position: relative;
  margin-left: -10px;
}
.custom-input .custom-input-label {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #003087;
  position: absolute;
  top: -6px;
  left: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  z-index: 99;
}

.custom-input .custom-input-input.ios {
  border-radius: 5px;
  border: solid 1px #959595;
}
.custom-input .custom-input-input {
  background-color: #ffffff;
  padding: 4px 14px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #003087;
}
.custom-input .custom-input-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.custom-input-showPassword {
  /* position: absolute;
  right: 0; */
  --background: transparent;
  --ionicon-stroke-width: 40px;
  /* margin-top: 35px; */
}

.custom-input-showPassword-desktop {
  /* position: relative;
  right: 0; */
  --background: transparent;
  --ionicon-stroke-width: 40px;
  /* margin-top: 15px; */
}

/* .custom-input-showPassword ion-icon {
  color: #2688d6;
} */

.android-showPassword {
  width: 26px;
  height: 26px;
  color: #0073cf;
}
.desktop-show-icon {
  color: #585858;
  width: 20px;
  height: 20px;
}
/* .custom-input-showPassword-desktop {
  position: absolute;
  right: 10px;
  top: 20px;
} */
