ion-content ion-toolbar {
  --background: transparent;
}

.no-padding {
  padding: 0px;
}

.dashboard-title {
  color: var(--ion-color-primary);
  font-size: 30px;
}

.dashboard-subtitle {
  color: var(--ion-color-secondary);
  font-size: 15px;
}

.dashboard-mtd-title {
  color: black;
  font-size: 16px;
}

.dashboard-avgcost-title {
  color: black;
  font-size: 16px;
}

.dashboard-card-number {
  color: var(--ion-color-primary);
  font-size: 35px;
}

.header-col {
  padding: 0px;
  margin: 0px;
}

.header-row {
  height: 105px;
}

.header-block {
  height: 105px;
  background-color: #fff;
  padding: 0px;
}

.snapShot {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #003087;
  margin-left: 60px;
}

.main-subtitle {
  color: #757575;
}

.main-title {
  font-weight: bold;
  color: #003087;
}

.snapShot-desktop {
  font-family: "Slate Pro";
  font-size: 48px;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: #003087;
  margin-top: 45px;
}
/* .store-icon {
  height: 60px;
  position: relative;
  top: 20px;
  width: 60px;
  color: #003087;
} */
.header-coll {
  width: 65px;
  display: table-cell;
  vertical-align: middle;
  margin-left: 20px;
}
.mobile-mrgns {
  margin-right: 5px;
  margin-left: 5px;
}
