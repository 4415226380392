.app-toolbar {
  --background: var(--ion-color-primary);
}

#root {
  height: 800px;
}

.card-title .blue-text {
  font-size: 18px;
  font-weight: 500;
  font-family: SFProText, Roboto, sans-serif;
}

.right-al {
  text-align: right;
}

.opacity70 {
  opacity: 0.7;
}

.backIcon {
  padding-left: 15px;
  font-size: 40px;
}

.icon-btn {
  cursor: pointer;
}

.width60 {
  width: 60%;
}

.width50 {
  width: 50%;
}

.hidden {
  display: none;
}

/* Main App Background */
.ion-page.mobile {
  background: linear-gradient(90deg, #fff 0%, #f3f8fd 100%);
  /* background: linear-gradient(90deg, #dcebff15 0%, #60a3db20 100%); */
}

.ion-page.desktop {
  background: linear-gradient(to right, #f8fbff, #e0edf8);
}

.list-ios {
  background: none;
}

.custom-button {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
  height: 40px;
  --background: linear-gradient(to bottom, #158fef 48%, #0073cf 49%);
}

.custom-button-white {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  --border-radius: 5px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #b6b6b6;
  height: 40px;
  --background: linear-gradient(to bottom, #ffffff 49%, #f7f7f7 50%);
  color: #0073cf;
}

.DeclineBtn {
  height: 40px;
  color: #0073cf;
  border: solid 1px #b6b6b6;
  --background: linear-gradient(to bottom, #ffffff 49%, #f7f7f7 50%);
  margin-top: 12px;
  width: 100%;
  margin-bottom: 5px;
}
@font-face {
  font-family: "Slate Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Slate Pro"), url("./fonts/SlatePro.woff") format("woff");
}

@font-face {
  font-family: "Slate Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Slate Pro Medium"),
    url("./fonts/SlatePro-Medium.woff") format("woff");
}
