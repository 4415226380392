.answer p {
  margin-top: 1em;
}

.answer a {
  color: #003087;
  line-height: 1.8em;
  text-decoration: underline;
}

.faq-bullet-list {
  list-style-type: circle;
  margin-top: 1em;
  margin-left: 2em;
}

.faq-bullet-list li {
  margin-top: 1em;
}
