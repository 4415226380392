.ios-mileage {
  font-family: "Slate Pro", sans-serif;
  font-size: 20px;
  line-height: 1.3;
  color: #003087;
  margin-left: 35px;
}
.ios-mileage-col {
  margin-top: -5px;
  margin-bottom: -20px;
}
