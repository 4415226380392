.idle-buttons-row {
  margin: 0px 10px;
  vertical-align: bottom;
}

.idle-buttons-row .custom-button,
.idle-buttons-row .custom-button-white {
  --border-radius: 5px;
  width: 100%;
}

.idle-modal-class {
  --max-height: 150px;
  --max-width: 320px;
  --border-radius: 5px;
  --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14) !important;
  --background-color: #ffffff;
  align-content: center;
}
