.loading-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #585858;
}
.loading-icon {
  width: 22px;
  height: 22px;
  color: #003087;
  margin-right: 7px;
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.loading-bg {
  --background: linear-gradient(151deg, #0073cf, #003087 47%, #003087 100%);
}
.centered-flex-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-image {
  width: 200px;
  height: 100px;
  margin: auto;
  padding: 20px;
  margin-bottom: 15px;
}

.loading-img-item {
  --padding-start: 0;
}

.signin-loading-card {
  width: 100%;
  height: 175px;
  margin-inline-start: 27px;
  margin-inline-end: 27px;
  border-radius: 5px;
}

.signin-loading-card-desktop {
  width: 30%;
  height: 175px;
  margin-inline-start: 27px;
  margin-inline-end: 27px;
  border-radius: 5px;
}
