.current-loc {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 20px;
  display: block;
}

#mobile-map .leaflet-container {
  height: 100%;
  /* height: -webkit-calc(100% - 10em);
  height: -moz-calc(100% - 10em);
  height: calc(100% - 10em); */
  z-index: 39;
  /* calc(64.4vh - 1px); */
  /* height: 500px; */
}

#desktop-map .leaflet-container {
  /* height: calc(100% - 10em); */
  height: 100%;
  z-index: 39;
}

.map-container {
  height: calc(100% - env(safe-area-insert-bottom));
  /* height: 100%; */
  width: 100%;
  /* mobile viewport bug fix */
  /* min-height: -webkit-fill-available; */
}
