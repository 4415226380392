.mb-40 {
  margin-bottom: 40px;
}

.scroll-content {
  padding-bottom: 0 !important;
}

.scroll-content {
  padding-bottom: 0 !important;
}
