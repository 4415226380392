.headerMap {
  font-size: 20px;
}

.inner-right {
  text-align: right;
}

.listGrid {
  align-items: center;
  text-align: center;
  margin-left: 17px;
  margin-right: 14px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.border-bottom {
  border-bottom: 1px dashed #b6b6b6;
}

.list-card-grid .content-row:last-child {
  border-bottom: none;
}

.listGrid ion-col {
  padding: 0;
}

.tripTextColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.vehicle-desktop-title {
  font-family: "Slate Pro", sans-serif;
  font-size: 48px;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: #003087;
  margin-left: 30px;
}
.vehicle-desktop-subtitle {
  font-family: "Slate Pro", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  color: black;
  margin-left: 30px;
}
.vehicle-desktop-title-item {
  --background: none;
  --padding-start: 0;
}
.mt-50 {
  margin-top: 50px;
}

.trip-label {
  font-family: "Slate Pro";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
}

.trip-data-label {
  font-family: "Slate Pro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.mobile-vehicleData-title {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  color: #003087;
  margin-left: 20px;
}
