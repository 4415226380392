.white-bg {
  background: white;
}

.sysCard {
  min-height: 180px;
}

.exp-title {
  font-size: 20px;
  color: black;
}

.card-list {
  font-size: 14px;
  color: black;
}

.card-footer {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.red-color {
  font-size: 16px;
  color: red;
}

.floating-location {
  position: absolute;
  right: 20px;
  top: 20px;
}

.black-loc {
  float: right;
  font-size: 22px;
  color: black;
}

ion-card p.mil-text {
  color: black;
  font-size: 22px !important;
  line-height: 22px;
  font-family: SFProText, Roboto, sans-serif;
}

/* ion-card p.mil-text span {
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
} */

.mil-header.third {
  white-space: nowrap;
}

ion-card .card-content-md p.mil-text.green-text {
  color: #003087;
}

.odo-text p {
  font-size: 16px;
  color: black;
}

.odo-text span {
  color: #585858;
  margin-left: 20px;
}

.info-icon {
  width: 30px;
  height: 30px;
  float: right;
  color: #0073cf;
}

.info-modal {
  width: 100%;
}

.info-modal > ion-row {
  min-height: 170px;
}

.info-modal .info-text {
  font-size: 18px;
  color: black;
  font-family: SFProText, Roboto, sans-serif;
}

.info-modal .custom-button {
  float: right;
}
.titles-text {
  padding: 0;
  margin-left: 15px;
  margin-top: 15px;
}

.mileage-text-col {
  text-align: center;
  height: auto;
}
.sysCard-title-item {
  --padding-start: 0;
  width: 100%;
}
.sysCard-title {
  font-family: "Slate Pro", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
  margin-left: 12px;
}
.sys-Card-miles {
  font-family: "Slate Pro", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.dashedRow {
  border-bottom: 1px dashed rgb(182, 182, 182);
  margin-left: 15px;
  margin-right: 15px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-30 {
  margin-left: 30px;
}
.card-content-ios p {
  align-items: center;
}

.description-container a {
  color: #003087;
}
