.newsfeed-card-content {
  white-space: pre-line;
}

.custom-card-title {
  display: block !important;
  font-family: "Slate Pro";
  font-size: 16px !important;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #000000;
  margin: 0px;
  margin-bottom: 3px;
  font-weight: 500 !important;
}

.custom-card-subtitle {
  font-family: "Slate Pro";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
  display: block !important;
  margin: 0px;
  margin-bottom: 3px;
}

.newsfeed-list .newsfeed-card:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.newsfeed-list .newsfeed-card:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.content-newsfeed-div {
  padding: 10px;
}

.newsfeed-title {
  font-family: "Slate Pro";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
  margin-left: 16px;
}

/* .newsfeed-desktop {
  max-height: 253px;
  min-height: 253px;
  border-bottom: 1px solid #d4d4d4;
  padding-right: 30px !important;
  padding-left: 30px !important;
  padding-top: 0;
  padding-bottom: 10px;
  overflow: auto;
}

@media only screen and (max-width: 900px) and (min-width: 750px) {
  .newsfeed-desktop {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
} */

.item-dashed {
  border-bottom: 1px dashed #d4d4d4;
}

.blue {
  color: #0073cf;
}

.yellow {
  color: #ffae00;
}
.red {
  color: #e01933;
}
.grey {
  color: #585858;
}
.newsfeed-icons {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.newsfeed-mobile {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  color: #003087;
}
.mobile-newsfeed-item {
  border-top: 1px solid #e7e7e7;
  --padding-start: 0;

  /* padding-left: 10px; */
}

.reset-padding {
  --padding-start: 16px;
}
