.slidePage {
  display: inline-block;
}

.center {
  margin: auto;
}

.fullscreen {
  display: flex;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

#logo {
  margin: auto;
  max-width: 500px;
  width: 70%;
}

#textContainer {
  width: 80%;
  margin: auto;
}

#buttonRow {
  margin-top: 10px;
}

.link {
  text-decoration: none;
  margin-top: 8px;
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  margin-bottom: 6px;
  color: #0073cf;
}

.link-item {
  --pading-start: 15px;
}

.login-rectangle {
  width: 100%;
  height: 176px;
  object-fit: cover;
}

.img-container {
  position: relative;
}

.img-container .top-img {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.img-container .welcome-text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.28;
  letter-spacing: 0.2px;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 39px;
  bottom: 96px;
  left: 24px;
}

.img-container .FairMile-text {
  font-size: 50px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 72px;
  bottom: 43px;
  left: 21px;
}

.img-container .sm-span {
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 73px;
  bottom: 30px;
  left: 198px;
}

.login-grid {
  height: 100%;
  padding: 0px;
}

.p-0 {
  padding: 0px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-9 {
  margin-top: 9px;
}
.login-card {
  padding: 5px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin: 24px;
  width: 100%;
}

.custom-input-item.ios {
  --padding-start: 16px;
  --padding-end: 8px;
}
.mb-ios.ios {
  margin-bottom: 20px;
}
.custom-input-item {
  --padding-start: 16px;
  --padding-end: 8px;
  padding-top: 3px;
}
.login-bg {
  background-color: #f3f8fd;
}
.signin-btn-color {
  --background: linear-gradient(to bottom, #e01933 49%, #b30032 50%);
}
.signin-btn-color.ios {
  --background: linear-gradient(to bottom, #158fef 48%, #0073cf 49%);
}
.swiper-slide {
  visibility: hidden;
}
.swiper-slide.swiper-slide-active {
  visibility: visible;
}
.Welcome-desktop {
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: #003087;
  margin-left: 180px;
}
.login-title-desktop {
  /* font-family: "Slate Pro"; */
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: #000000;
}
.no-background-desktop {
  --background: none;
}
.login-card-desktop {
  margin-left: 200px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  width: 55%;
}
.desktop-input {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px #e7e7e7;
  border-bottom: 1px solid #b6b6b6;
}
.sm-span-desktop {
  font-size: 15px;
  margin-bottom: 40px;
  margin-left: 1px;
  color: #003087;
  font-weight: 600 !important;
}
.header-span {
  font-size: 25px;
  position: absolute;
}
.w-520 {
  width: 520px;
  margin-left: 120px;
}
.ml-95 {
  margin-left: 95px;
}
.desktop-login-btn {
  margin-left: 175px;
  margin-top: 10px;
}
.ml-90 {
  margin-left: 90px;
}

.email-box {
  margin-right: 20%;
  margin-bottom: 24px;
  margin-top: 10px;
}

.password-box {
  margin-right: 20%;
  margin-top: 10px;
}
