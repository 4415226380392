.title {
  color: var(--ion-color-primary);
  font-size: 30px;
}
.support-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #003087;
}
.help-pos {
  margin-right: 24px;
  margin-bottom: 30px;
  margin-left: 25px;
}
.support-card {
  margin: 10px;
}
.support-icon {
  width: 20px;
  color: #003087;
}
.support-options {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #003087;
  margin-left: 25px;
  margin-right: 10px;
  text-transform: none;
}

.right-arrow {
  color: #003087;
  width: 20px;
  height: 20px;
  font-weight: bold;
  margin-bottom: -4px;
  right: 25px;
}
.right-arrow {
  position: absolute;
}
.inner-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: #003087;
  margin-left: 10px;
}

.help-ionitem {
  --padding-start: 0;
}
.desktop-support {
  font-family: SlatePro-Bk, sans-serif;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: #003087;
  margin-left: 24px;
}
.desktop-help {
  font-family: "Slate Pro";
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: black;
  margin-left: 24px;
}
.desktop-card-text {
  font-family: "Slate Pro";
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
  margin-left: 15px;
}
.desktop-downIcon {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 15px;
  height: 15px;
}
.blueText {
  font-family: "Slate Pro", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: #003087;
}
.inner-text-desktop {
  font-family: SlatePro-Bk, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: black;
}
.grey-line {
  position: absolute;
  right: 0px;
  left: 0px;
  background-color: #e7e7e7;
  height: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2;
}
.desc-text {
  font-family: SlatePro-Bk, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
}
.blue-up {
  color: #003087;
}
.ml-0 {
  margin-left: 17px;
}
.card-content-ios {
  padding-left: 0;
}
