.slidePage {
  display: inline-block;
}

.center {
  margin: auto;
}

.fullscreen {
  display: flex;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

#logo {
  margin: auto;
  max-width: 500px;
  width: 70%;
}

#textContainerTOS {
  width: 40%;
  margin: auto;
}

.buttonRow {
  margin-top: 10px;
}

.AcceptBtn {
  width: 100%;
  background-color: #158fef;
}

.termsContent {
  margin-bottom: 10px;
  min-height: 300px;
  text-align: justify;
  font-style: normal;
  font-size: 0.8em;
  line-height: 23px;
}

.contentRow {
  min-height: 30%;
}

.tos-grid {
  height: 100%;
  padding: 0px;
}

.tos-title.ios {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 48px;
}
.tos-title {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #003087;
  direction: ltr;
  text-align: left;
  margin-top: 17px;
  margin-left: 35px;
  margin-bottom: 16px;
}

.tos-content.ios {
  line-height: 1.5;
  color: #000000;
  padding-left: 8px;
  margin-top: -10px;
}
.tos-content {
  direction: ltr;
  text-align: left;
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #585858;
  margin-top: -15px;
  margin-left: 24px;
  margin-right: 10px;
  max-height: 320px;
  overflow: auto;
}
.tos-row {
  background-color: linear-gradient(153deg, #0073cf, #003087 47%, #003087);
}

.custom-page-bg.ios {
  --background: linear-gradient(153deg, #003087, #003087 47%, #0073cf);
}
.custom-page-bg {
  background-color: #f8fbfe;
}

.tos-card.ios {
  margin: 26px;
  border-radius: 5px;
  background-color: white;
}
.tos-card {
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: #f8fbfe;
}

.tos-logo {
  margin: auto;
  height: 61px;
  padding-left: 10px;
  margin-top: 21px;
  margin-bottom: 38px;
}

.tos-item-logo {
  --padding-start: 12px;
}

.decline-text {
  height: 52px;
  font-family: SFProText, Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #bd8100;
}
.decline-img {
  width: 30px;
  height: 30px;
  margin-bottom: 27px;
  margin-right: 9px;
}

.card-content-ios {
  padding-top: 7px;
  padding-bottom: 17px;
}

.Btn-item.ios {
  --inner-padding-end: 25px;
  --inner-padding-start: 10px;
  --background: white;
  margin-top: 15px;
}
.Btn-item {
  --inner-padding-end: 33px;
  --inner-padding-start: 20px;
  --background: #f8fbfe;
}
.tos-link-pos {
  margin-left: 23px;
  --background: #f8fbfe;
}
.tos-link-pos.ios {
  margin-left: 10px;
  --background: white;
}
.decline-pos {
  margin-left: 25px;
}
.decline-pos.ios {
  margin-left: 20px;
}
.desktop-tos-title {
  font-size: 48px;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: #003087;
}
.no-bg {
  --background: none;
}

.tos-sup {
  top: -20px;
  left: 2px;
  font-size: 15px;
  color: #003087;
  font-weight: bold;
}
.desktop-tos-cardtitle {
  font-family: "Slate Pro", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
}
.desktop-tos-cardtitle-pos {
  margin-top: 15px;
}
.cardcontent-text {
  font-family: "Slate Pro", sans-serif;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
  margin-top: 5px;
}
.declineBtn-desktop {
  color: #0073cf;
  background-color: white;
  height: 40px;
  width: 180px;
  font-weight: bold;
}
.hydrated.ipad-bg {
  --background: linear-gradient(to right, #f8fbff, #e0edf8);
}

.terms p {
  margin-bottom: 1em;
}

.terms li {
  margin-bottom: 1em;
  list-style-type: decimal;
  /* text-justify: ful; */
}

.terms h2 {
  margin-bottom: 2em;
  list-style-type: decimal;
  /* text-justify: ful; */
}
