ion-header {
  position: relative;
}

ion-toolbar {
  --background: transparent;
}
.no-padding {
  padding: 0px;
}

.header-title {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.86;
  color: #ffffff;
  padding-inline-start: 0;
  padding-inline-end: 0px;
  text-align: center;
  margin-bottom: 15px;
}

.header-title.ios {
  font-size: 18px;
  margin-bottom: 0;
}

.header-subtitle {
  margin-left: 5px;
}

.header-md::after {
  display: none;
}

.toolbar-bg {
  background: linear-gradient(151deg, #003087, #003087 47%, #0073cf 100%);
  /* env(safe-area-inset-top, 20px); */
  color: white;
  height: 46px;
}

.toolbar-bg.ios {
  height: auto;
}

.header-fixed-text {
  color: white;
  margin-left: 30px;
  font-weight: 80px;
}

.header-div {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.header-branding {
  height: 45px;
  width: 53px;
  position: absolute;
  right: 0;
  top: var(--ion-safe-area-top, 0);
  z-index: 99;
}
/* .header-branding.ios {
  height: 64px;
} */
.header-icon {
  margin-bottom: 15px;
}
.header-icon.ios {
  margin-bottom: 0;
}
.menu-button {
  margin-bottom: 15px;
}
.menu-button.ios {
  margin-bottom: 0;
}
.header-sm {
  font-family: SFProText, Roboto, sans-serif;
  padding: 2px;
  font-size: 10px;
  position: absolute;
  left: 77px;
}
.header-sm.ios {
  left: 62px;
  padding: 2px;
}
