.custom-item {
    --inner-border-width: 0px;
    --padding-start: 0;
    --padding-end: 0;
    --color-hover: black;
    --background-hover: white;
    width: fit-content;
}

.action-icon {
    margin-left: 8px;
}