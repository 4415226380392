.white-bg {
  background: white;
}

.sysCard {
  min-height: 180px;
}

.exp-title {
  font-size: 20px;
  color: black;
}

.card-list {
  font-size: 14px;
  color: black;
}

.card-footer {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.red-color {
  font-size: 16px;
  color: red;
}

.floating-location {
  position: absolute;
  right: 20px;
  top: 20px;
}

.black-loc {
  float: right;
  font-size: 22px;
  color: black;
}

ion-card .card-content-md p.mil-header {
  color: #585858;
  font-size: 10px;
  text-align: center;
  line-height: 1.6;
  letter-spacing: 1.5px;
}

ion-card .card-content-md p.mil-text {
  color: black;
  font-size: 17px;
  text-align: center;
  font-weight: bold;
}

ion-card .card-content-md p.mil-text.green-text {
  color: green;
}

.subtitle-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #003087;
  margin-left: 30px;
}
.alert-circle {
  color: #0073cf;
  width: 27px;
  height: 27px;
}
.bold-label {
  font-family: "Slate Pro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: black;
  display: block;
}
.lighter-text {
  color: #6d7278;
  margin-right: 12px;
}
.auto-height {
  height: auto;
}

.see-more-listCard {
  border-top: 1px solid #b6b6b6;
}
.list-card-col {
  margin: 16px;
}
.ml-15 {
  margin-left: 15px;
}
.paddings {
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 1px;
}
.mb--10 {
  margin-bottom: -10px;
}
.trip-label-style {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 2;
  color: #585858;
}
ion-card.md ion-card-content.md {
  padding-right: 0px;
  padding-left: 0px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-40 {
  margin-left: 40px;
}
.red-div {
  background-color: #e01933;
  height: 100%;
  position: absolute;
  left: 0;
  width: 30px;
  z-index: 10;
}

.alert-icon {
  height: 30px;
  color: white;
  position: absolute;
  left: 0;
  z-index: 9999;
  width: 30px;
  top: 15px;
}
.custom-info-icon {
  transform: rotate(180deg);
}
.ml-10 {
  margin-left: 10px;
}
