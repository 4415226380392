.viewMap-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: normal;
  color: #0073cf;
  position: absolute;
  right: 30px;
  bottom: -12px;
  text-transform: capitalize;
}
.viewMap-icon {
  position: absolute;
  bottom: 2px;
  right: 15px;
  width: 25px;
  height: 25px;
  color: #003087;
}
.blue-div {
  background-color: #0073cf;
  height: 100%;
  position: absolute;
  left: 0;
  width: 30px;
  z-index: 10;
}
.vehicles-loc-icon.ion-no-padding.ion-no-margin.blue-icon {
  color: #003087;
  margin-left: 5px;
}
.ml-30 {
  margin-left: 30px;
}
