.tabs-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
}
.vehicles-tabs {
  border-radius: 4px;
  margin-top: 21px;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 15px;
  background-color: white;
  cursor: pointer;
  padding-bottom: 0;
}
.tab-col {
  font-family: "Slate Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 2.22;
  letter-spacing: 1.25px;
  color: #0073cf;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
.tab-col:focus {
  outline: none;
}
.tab-col.tab-col-active {
  color: #003087;
  border: none;
  border-bottom: 2px solid #003087;
  cursor: pointer;
}
.vehicle-tabs-item {
  --padding-start: 0;
  text-align: center;
  font-family: "Slate Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 2.22;
  letter-spacing: 1.25px;
  color: #0073cf;
  cursor: pointer;
}
