.message-title {
  font-weight: bold;
}

.call-to-action {
  color: var(--ion-color-secondary);
  font-size: 15px;
}

.newsfeed-card-content {
  white-space: pre-line;
}

.card-lines {
  font-size: 13px;
  color: black;
  font-weight: 600;
}

.floated-icons {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}

.floated-icons ion-button {
  cursor: default;
  position: absolute;
  right: 30px;
}

.locIcon,
.graphIcon {
  color: black;
  font-size: 20px;
}

.modal-class {
  --max-height: 250px;
  --max-width: 320px;
  --border-radius: 5px;
  --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14) !important;
  --background-color: #ffffff;
  align-content: center;
}

.modal-class-sns {
  border: 1px solid black;
  --max-height: 350px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --max-width: 600px;
}

.modal-class-sns .Label-class.label-floating.sc-ion-label-md-h {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}
.vehicle-edit-input {
  margin-bottom: 28px;
}
/* .vehicle-edit-input .Label-class {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
} */
.vehicle-edit-input .custom-input .custom-input-input {
  margin-top: 23px;
}

.buttons-row {
  margin: 0px 10px;
}

.buttons-row .custom-button,
.buttons-row .custom-button-white {
  --border-radius: 5px;
  width: 100%;
}

.row-centered {
  justify-content: center;
}

.modal-class .ionItem {
  margin-bottom: 30px;
  margin-top: 50px;
} /********************************************************************************************/

.search-vehicles {
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 5px;
  border: solid 1px #959595;
}
.search-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #585858;
  margin-left: 24px;
}
.searchV-icon {
  width: 25px;
  height: 25px;
  color: #003087;
}
.vehicles-card {
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 21px;
  margin-bottom: 32px;
}
.left-rect {
  position: absolute;
  left: 0;
  width: 32px;
  background-color: #003087;
  height: 100%;
  z-index: 2;
}

.vehiclesCard-header {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.odometer-title {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-left: 25px;
}
.odometer-value {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #585858;
  margin-left: 25px;
}

.vehiclesCard-sub {
  font-size: 0.9em;
}
.vehicles-loc-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 40px;
  height: 40px;
  color: #585858;
  z-index: 1000;
}
.vehicle-loc-icon ion-icon {
  width: 30px;
  height: 30px;
}

.vehicles-stats-icon {
  position: absolute;
  right: 15px;
  top: 60px;
  width: 28px;
  height: 20px;
  color: #003087;
  z-index: 1000;
}
.edit-vehicle {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #0073cf;
  position: absolute;
  right: 0px;
  bottom: 3px;
  z-index: 2;
  --background: none !important;
  --box-shadow: none !important;
  text-transform: capitalize;
  padding-top: 20px;
}
.track-package {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0073cf;
  margin-left: 25px;
}
.lastTrip-val {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #585858;
  margin-left: 25px;
}
.modal-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}
.vehicles-card-shadow {
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.4);
}

.margin-left-10 {
  margin-left: 10px;
}

.no-margin-left {
  margin-left: 10px;
}

/* .map-area {
  height: 330px;
  width: 100%;
  border-bottom: 1px solid #b6b6b6;
  position: relative;
}

.map-area .leaflet-container {
  height: 329px;
  position: absolute;
  width: 100%;
}
.mileage-card-info .leaflet-container {
  height: 183px;
  position: absolute;
  width: 100%;
}

.mileage-card-info .map-area {
  height: 184px;
} */

.device-map-area {
  height: 245px;
  width: 100%;
  position: relative;
}

.device-map-area .leaflet-container {
  height: 245px;
  position: absolute;
  width: 100%;
}

.vehicle-map-area {
  height: 349px;
  width: 100%;
  position: relative;
}

.vehicle-map-area .leaflet-container {
  height: 349px;
  position: absolute;
  width: 100%;
}

.small-map-area {
  height: 186px;
  border-bottom: 1px solid #b6b6b6;
  position: relative;
}

.small-map-area .leaflet-container {
  height: 186px;
  position: absolute;
  width: 100%;
}
