.no-padding {
  padding: 0px;
}

.snapshot-moreBtn {
  position: absolute;
  width: 144px;
  right: 16px;
  bottom: 20px;
  background-color: #0073cf;
}

.main-card {
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.4);
  margin-top: 8px;
  margin-bottom: 0;
}

.snapshotCard-text {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}
.card-num {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 35px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #003087;
}
.insightsBtn {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.desktop-snapshotCard {
  height: 400px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.desktop-snapshotCard.vehicles {
  height: 442px;
}
.desktop-snapshotCard.vehicles-not-active {
  height: 226px;
}
.desktop-item {
  --padding-start: 0;
}
.desktop-card-title {
  font-family: "Slate Pro";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: black;
}
.desktop-card-title.mobile {
  font-size: 18px !important;
}
.desktop-card-subtitle {
  font-family: "Slate Pro";
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #585858;
}
.card-num-desktop {
  font-family: "Slate Pro";
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.desktop-line {
  color: #b6b6b6;
  height: 2px;
}
.seeMore-desktop {
  font-family: "Slate Pro";
  font-size: 18px;
  font-weight: 600;
  line-height: 2.22;
  letter-spacing: 1.25px;
}
.insights-text-desktop {
  color: #003087;
}
.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml-45 {
  margin-left: 45px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-75 {
  margin-left: 75px;
}
