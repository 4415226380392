.tabbar-label {
  font-family: SFProText, Roboto, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #585858;
}

.tab-button {
  position: relative;
}
.tab-button.tab-selected::after {
  content: "";
  height: 2px;
  border-top: 2px solid #e01933;
  position: absolute;
  width: 40%;
  bottom: 3px;
  left: 30%;
}
